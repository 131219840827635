<template>
  <div id="home">
    <PayloadPage v-if="payloadData" v-bind="payloadData" />
  </div>
</template>

<script type="module">
import {
  defineComponent,
  useFetch,
  onMounted,
  useMeta,
  ref,
} from '@nuxtjs/composition-api';
import { useGtm, useUser, useHome } from '~/composables';
import { useCache, CacheTagPrefix } from '@gemini/vsf-cache';

export default defineComponent({
  name: 'HomePage',
  components: {
    PayloadPage: () => import('~/pages/PayloadPage.vue'),
  },
  head: {},
  setup() {
    const { isAuthenticated } = useUser();
    const { pageViewPush } = useGtm();
    const { addTags } = useCache();
    const {
      loadHomeBlocks,
      loadStructuredData,
      populateHomeMeta,
      homeBlocks,
      homeMeta,
    } = useHome();

    const payloadData = ref();

    useFetch(async () => {
      await loadHomeBlocks();
      await loadStructuredData();
      await populateHomeMeta();
      payloadData.value = {
        contentPage: homeBlocks?.value,
        showBreadcrumbs: false,
      };
      addTags([{ prefix: CacheTagPrefix.View, value: 'homepage' }]);
    });

    onMounted(() => {
      pageViewPush('HomePage');
    });

    useMeta(() => homeMeta.value);

    return {
      isAuthenticated,
      payloadData,
    };
  },
});
</script>
